<template>
  <section>
    <b-card
      no-body
      class="mb-0"
      header-tag="header"
    >
      <template #header>
        <div class="d-sm-flex justify-content-between">
          <div class="d-flex align-items-center">
            <h6 class="mb-0 text-uppercase font-weight-bold-700">
              Tra cứu hồ sơ
            </h6>
          </div>
        </div>
      </template>

      <div class="card-body">
        <b-card>
          <b-row>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Lĩnh vực"
                label-for="input-1"
              >
                <treeselect
                  id="linhVuc"
                  v-model="filter.dataLinhVucId"
                  v-format-v-select
                  :options="danhSachLinhVuc"
                  placeholder="Chọn lĩnh vực"
                  :clear-on-select="true"
                  :limit="1"
                  multiple
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  :normalizer="normalizer"
                  :match-keys="['label', 'label2']"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Mã biên nhận"
                label-for="input-1"
                class="grap-5 input-top"
              >
                <b-form-input
                  id="maHoSo"
                  v-model="filter.maHoSo"
                  v-trim-input
                  type="text"
                  placeholder="Nhập mã biên nhận"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                id="input-group-1"
                label="Chọn thủ tục hành chính"
                label-for="input-1"
                class="grap-6"
              >
                <treeselect
                  id="linhVuc"
                  ref="tenThuTucHanhChinh"
                  v-model="filter.dataTTHCId"
                  v-format-v-select
                  :default-options="danhSachThuTucHanhChinh"
                  placeholder="Chọn thủ tục hành chính"
                  :limit="0"
                  multiple
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  loading-text="Đang tìm kiếm"
                  :normalizer="normalizer"
                  :match-keys="['label', 'label2']"
                  :load-options="onQueryChange"
                  :async="true"
                  :clear-on-select="true"
                  @select="$refs.tenThuTucHanhChinh.resetSearchQuery()"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, shouldShowCount, count, labelClassName }"
                    :class="labelClassName"
                    :title="node.label"
                  >
                    {{ node.label }}
                  </label>
                </treeselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Tiếp nhận từ ngày"
                label-for="input-1"
                class="grap-6"
              >

                <date-picker
                  v-model="filter.thoiGianTiepNhanTu"
                  format="DD/MM/YYYY"
                  placeholder="Từ ngày"
                  class="h-100 w-100 grap-6"
                />

              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Đến ngày"
                label-for="input-1"
                class="grap-6"
              >
                <date-picker
                  v-model="filter.thoiGianTiepNhanDen"
                  format="DD/MM/YYYY"
                  placeholder="Đến ngày"
                  class="h-100 w-100 grap-6"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Thời gian hẹn trả từ ngày"
                label-for="input-1"
                class="grap-6"
              >

                <date-picker
                  v-model="filter.thoiGianHenTraTu"
                  format="DD/MM/YYYY"
                  placeholder="Từ ngày"
                  class="h-100 w-100 grap-6"
                />

              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Đến ngày"
                label-for="input-1"
                class="grap-6"
              >
                <date-picker
                  v-model="filter.thoiGianHenTraDen"
                  format="DD/MM/YYYY"
                  placeholder="Đến ngày"
                  class="h-100 w-100 grap-6"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">

              <b-form-group
                id="input-group-1"
                label="Đơn vị"
                label-for="input-1"
                class="grap-6"
              >
                <treeselect
                  id="linhVuc"
                  v-model="filter.donViId"
                  v-format-v-select
                  :options="danhSachDonVi"
                  placeholder="Chọn đơn vị"
                  :normalizer="normalizer_DonVi"
                  :limit="1"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  value-consists-of="LEAF_PRIORITY"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  :match-keys="['label', 'name']"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, shouldShowCount, count, labelClassName }"
                    :class="labelClassName"
                    :title="node.label"
                  >
                    {{ node.label }}
                  </label>
                </treeselect>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Kiểu tiếp nhận"
                label-for="input-1"
                class="grap-6"
              >
                <treeselect
                  id="linhVuc"
                  v-model="filter.kieuTiepNhan"
                  v-format-v-select
                  :options="optionsKieuTiepNhan"
                  :limit="0"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder="Chọn kiểu tiếp nhận"
                  :clear-on-select="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Trạng thái hồ sơ"
                label-for="input-1"
                class="grap-6"
              >
                <treeselect
                  id="linhVuc"
                  v-model="filter.trangThaiHoSo"
                  v-format-v-select
                  multiple
                  :options="danhSachTrangThaiHoSo"
                  :limit="0"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder="Chọn trạng thái hồ sơ"
                  :clear-on-select="true"
                  :normalizer="normalizer"
                  :match-keys="['label', 'label2']"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, shouldShowCount, count, labelClassName }"
                    :class="labelClassName"
                    :title="node.label"
                  >
                    {{ node.label }}
                  </label>
                </treeselect>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Chủ hồ sơ"
                label-for="input-1"
                class="grap-5 input-top"
              >
                <b-form-input
                  id="maHoSo"
                  v-model="filter.chuHoSo"
                  v-trim-input
                  type="text"
                  placeholder="Nhập chủ hồ sơ"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="d-flex justify-content-end align-items-center mb-1">
                <button
                  class="compact ui btn-primary button d-flex align-items-center"
                  @click="filterDanhSachHoSo()"
                >
                  <search-icon
                    size="12"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  <span class="fix">Tìm kiếm</span>
                </button>
                <button
                  class="compact ui btn-primary button d-flex align-items-center"
                  @click="refreshCommon"
                >
                  <refresh-cw-icon
                    size="12"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  <span class="fix">Làm mới</span>
                </button>
              </div>
            </b-col>
          </b-row>
          <div>
            <hr>
          </div>
          <div class="mt-2">
            <b-row>
              <b-col cols="6">
                <h4 class="text-color">
                  Kết quả tìm kiếm
                </h4>
              </b-col>
              <b-col
                cols="6"
                class="mt--2"
              >
                <div class="d-sm-flex justify-content-end">
                  <button
                    class="compact ui btn-primary button d-flex align-items-center"
                    @click="onPrint"
                  >
                    <printer-icon
                      size="12"
                      class="custom-class"
                      :stroke-width="3"
                    />
                    <span class="fix">In danh sách</span>
                  </button>
                </div>
              </b-col>
            </b-row>
          </div>
          <good-table
            ref="hoSo"
            class="mt-1 fixed-column"
            :columns="columns"
            :rows="danhSachHoSo"
            :total="tongSoHoSo"
            :selectable="false"
            @selected-item="onSelectRow"
            @page-change="filterDanhSachHoSo"
          >
            <template
              slot="custom-row"
              slot-scope="props"
            >
              <div
                v-if="props.props.column.field === 'tenTrangThai'"
                class="custom-column"
              >
                <div>{{ formatDateTime(props.props.row.ngayNop) }}</div>
                <div>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</div>
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="props.props.row.tenLoaiTrangThai" />
                <!--eslint-enable-->
                <div
                  v-if="props.props.row.thoiGianDenHanTraKQ"
                  :class="props.props.row.thoiGianDenHanTraKQ.indexOf('Quá hạn') >= 0 ? 'text-red-cor' : 'text-green-cor'"
                >
                  {{ props.props.row.thoiGianDenHanTraKQ }}
                </div>
              </div>
              <span v-if="props.props.column.field == 'code'">
                <a
                  href="javascript:;"
                  @click="detailHoSo(props.props.row)"
                >{{ `${props.props.row.code}` }}</a>
                <div>
                  <cloud-icon
                    size="15"
                    class="custom-class"
                  /> {{ props.props.row.kieuTiepNhan }}
                </div>
                <div v-if="props.props.row.dichVuChuyenPhatKetQua">
                  <send-icon
                    size="15"
                    class="custom-class"
                  /> Dịch vụ chuyển phát kết quả
                </div>
              </span>
              <div
                v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
                class="custom-column"
              >
                <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
                  <div class="text-yellow-cor">
                    {{ props.props.row.tenTrangThai }}
                  </div>

                </div>
                <div v-else>
                  <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
                  <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
                  <div class="text-yellow-cor">
                    {{ props.props.row.tenTrangThai }}
                  </div>
                  <div
                    v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
                    :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
                  >
                    {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
                  </div>
                </div>

              </div>
              <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
                <div v-if="props.props.row.chuHoSo_HoVaTen">
                  <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
                </div>
                <div
                  v-if="props.props.row.chuHoSo_DiaChiDayDu"
                  class="text-top word-break"
                >
                  <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
                </div>
              </div>
              <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
                {{ props.props.row.tenThuTucHanhChinh }}
              </div>
            </template>
            <template
              slot="actions"
              slot-scope="props"
            >
              <div class="text-center">
                <eye-icon
                  v-b-tooltip.hover.v-secondary
                  title="Chi tiết"
                  size="16"
                  class="custom-class mr-1 cursor-pointer"
                  @click="detailHoSo(props.props.row)"
                />
              </div>

            </template>
          </good-table>
        </b-card>
      </div>
      <common-modal
        ref="commonModal"
        :title="title"
        :size="size"
      />
      <iframe
        id="printf"
        name="printf"
        class="d-none"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import { PER_PAGE_OPTIONS } from '@/constants/constants'
import DatePicker from 'vue2-datepicker'
import {
  EyeIcon, CloudIcon, UserIcon,
  MapPinIcon,
  SearchIcon,
  PrinterIcon,
  RefreshCwIcon,
  SendIcon,
} from 'vue-feather-icons'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import addCssSemantic from '@/mixins/mixins'
import END_POINTS from '@/api/endpoints'
import GoodTable from '@/components/GoodTable.vue'
// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { removeDiacritical, formatDateTime, formatDateRequest } from '@/utils/index'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import { STATUS_TIEP_NHAN } from '../../../constants/constants'

export default {
  name: 'TraCuuHoSo',
  components: {
    BFormGroup,
    commonModal,
    EyeIcon,
    SearchIcon,
    PrinterIcon,
    RefreshCwIcon,
    BCard,
    CloudIcon,
    SendIcon,
    GoodTable,
    BRow,
    UserIcon,
    MapPinIcon,
    BCol,
    BFormInput,
    DatePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      perPageOptions: PER_PAGE_OPTIONS,
      optionsKieuTiepNhan: STATUS_TIEP_NHAN,
      totalPages: 0,
      totalRows: 0,
      isShow: false,
      isLoading: false,
      selectedItems: [],
      danhSachThuTucHanhChinh: [],
      danhSachLinhVuc: [],
      danhSachDonVi: [],
      tongSoHoSo: 0,
      total: 0,
      size: 'lg',
      pageChange: [],
      danhSachTrangThaiHoSo: [],
      title: 'Chú ý',
      title_2: 'Thông báo',
      danhSachHoSo: [],
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: 'auto',
          thClass: 'text-center',
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'tenTrangThai',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Chi tiết',
          field: 'tenLoaiTrangThai',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      danhSach: [],
      filter: {
        donViId: null,
        kieuTiepNhan: null,
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: null,
        thoiGianTiepNhanDen: null,
        thoiGianHenTraTu: null,
        thoiGianHenTraDen: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      },
    }
  },
  created() {
    this.getDanhSachThuTucHanhChinh()
    this.getDanhSachLinhVuc()
    this.getTrangThaiHoSo()
    this.filterDanhSachHoSo()
    this.getDonViTheoDonViGoc()
  },
  methods: {
    formatDateRequest,
    formatDateTime,
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.danhSachThuTucHanhChinh.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    detailHoSo(data) {
      this.$router.push({
        path: '/ho-so/chi-tiet',
        query: { id: data.id, name: this.$route.name },
      })
    },
    getDanhSachThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachThuTucHanhChinh = response.data.data
        }
      })
    },
    getDanhSachLinhVuc() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachLinhVuc = response.data.data
        }
      })
    },
    getDonViTheoDonViGoc() {
      this.$axios.get(END_POINTS.CHUNG.DON_VI, false).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.danhSachDonVi = this.listToTree(formatDonVi)
        }
        this.data = this.filter
      })
    },
    getTrangThaiHoSo() {
      this.$axios.get(END_POINTS.TRANG_THAI.COMBOBOX, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachTrangThaiHoSo = response.data.data
        }
      })
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    filterDanhSachHoSo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
        maHoSo: this.filter.maHoSo ? this.filter.maHoSo.replace(/\s+/g, ' ').trim() : '',
        chuHoSo: this.filter.chuHoSo ? this.filter.chuHoSo.replace(/\s+/g, ' ').trim() : '',
        thoiGianTiepNhanTu: formatDateRequest(this.filter.thoiGianTiepNhanTu),
        thoiGianTiepNhanDen: formatDateRequest(this.filter.thoiGianTiepNhanDen),
        thoiGianHenTraTu: formatDateRequest(this.filter.thoiGianHenTraTu),
        thoiGianHenTraDen: formatDateRequest(this.filter.thoiGianHenTraDen),
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      if (param.code) {
        payload = {
          ...payload,
          maHoSo: param.code ? param.code.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      this.$axios.post(END_POINTS.TRA_CUU.TRA_CUU_HO_SO, payload, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachHoSo = response.data.data
          this.tongSoHoSo = response.data.totalCount || 0
        }
      })
    },
    refreshCommon() {
      this.filter = {
        donViId: null,
        kieuTiepNhan: null,
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: null,
        thoiGianTiepNhanDen: null,
        thoiGianHenTraTu: null,
        thoiGianHenTraDen: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.hoSo.$refs.vbTables.reset()
      this.filterDanhSachHoSo()
    },
    async onPrint() {
      const payload = {
        ...this.filter,
        pageNumber: this.filter.pageNumber,
        pageSize: this.tongSoHoSo,
      }
      await this.$axios.post(END_POINTS.TRA_CUU.TRA_CUU_HO_SO, payload, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSach = response.data.data
        }
      })
      const newWin = window.frames.printf
      let printEle = `
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
          <tr>
            <th style="border: 1px solid black; padding: 10px; mim-width: 50px !important; text-align: center;">
              STT
            </th>
            <th style="border: 1px solid black; padding: 10px;  min-width: 150px !important; text-align: center;">
              Mã biên nhận
            </th>
            <th style="border: 1px solid black; padding: 10px; min-width: 310px !important; text-align: center;">
              Tên thủ tục hành chính
            </th>
            <th style="border: 1px solid black; padding: 10px; min-width: 40px !important; text-align: center;">
              Chủ hồ sơ
            </th>
            <th style="border: 1px solid black; padding: 10px; min-width: 120px !important; text-align: center;">
              Trạng thái
            </th>
             <th style="border: 1px solid black; padding: 10px; min-width: 120px !important; text-align: center;">
              Chi tiết
            </th>
          </tr>
      `
      await this.danhSach.forEach((item, index) => {
        printEle += `
          <tr>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              ${index + 1}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: left;">
              ${item.code ? item.code : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: left;">
             ${item.tenThuTucHanhChinh ? item.tenThuTucHanhChinh : ''}
             
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: left;">
            <div>
            ${item.chuHoSo_HoVaTen ? item.chuHoSo_HoVaTen : ''}
             </div>
              <div style="margin-top:6px;">
              ${item.chuHoSo_DiaChiDayDu ? item.chuHoSo_DiaChiDayDu : ''}
              </div>
              
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: left;">
               <p>${item.ngayNop ? formatDateTime(item.ngayNop) : ''}</p>
                <p>${item.ngayHenTraKq ? formatDateTime(item.ngayHenTraKq) : ''}</p>
                <p>
                  ${item.tenLoaiTrangThai ? item.tenLoaiTrangThai : ''}
                </p>
                <p class="text-green-cor">
                 ${item.thoiGianDenHanTraKQ ? item.thoiGianDenHanTraKQ : ''}
                </p>
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: left;">
                <p>${item.thoiGianBatDauTrangThai ? formatDateTime(item.thoiGianBatDauTrangThai) : ''}</p>
                <p>${item.thoiGianKetThucTrangThai ? formatDateTime(item.thoiGianKetThucTrangThai) : ''}</p>
                <p class="text-yellow-cor">
                  ${item.tenTrangThai ? item.tenTrangThai : ''}
                </p>
                <p class="text-green-cor">
                  ${item.thoiGianDenHanKetThucTrangThai ? item.thoiGianDenHanKetThucTrangThai : ''}
                </p>
            </td>
          </tr>
        `
      })
      printEle += '</table>'
      // eslint-disable-next-line prefer-template
      newWin.document.write(printEle)
      newWin.document.close()
      newWin.focus()
      newWin.print()
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    normalizer_DonVi(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          children: node.children,
          name: removeDiacritical(node.tenDonVi),
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
        name: removeDiacritical(node.tenDonVi),
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .input-top{
    margin-top:-0.01rem;
  }
  .mt--2{
    margin-top:-0.5rem;
  }
  // .text-color{
  //   color:#1380FF;
  // }
  .cs-table {
    min-height: 150px;
    .actions-col-width {
      max-width: 50px;
    }
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }
  .custom-column {
  p {
    margin-bottom: 0;
  }
}
.text-top{
    margin-top:6px;
  }
  .fix{
    margin-left:5px;
  }
</style>
